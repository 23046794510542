import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Paper } from "@mui/material";
import { TabPanel, TabList, TabContext, Tab } from "@mui/lab";
import { styled } from "@mui/system";
import Slideshow from "./Slideshow";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import {companyName, companyShortName, textColor, cardWidth} from './constants'
import Stack from '@mui/material/Stack';

const drawerWidth = 240;
const navItems = [{label:"Home", path:"/"}, {label:"About", path:"/about"}, {label:"Reviews", path:"/reviews"}, {label:"Contact", path:"/contact"}];


function Home(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  return (
    <Box sx={{ marginTop: 2 }}>
      <CssBaseline />
      
      <Box component="main" sx={{ p: 2 }}>
      <Stack spacing={2} direction="column" alignItems="center">
        <Typography variant="h4" sx={{ color: textColor }}>
          Welcome to {companyShortName}
        </Typography>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

        <Card sx={{ maxWidth: cardWidth }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="240"
              image="/Assets/work/craft.jpeg"
              alt="craft"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" sx={{ color: textColor }}>
                Crafting Your Dreams into Reality
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ color: textColor }}>
                At {companyShortName}, we understand that your home is more than
                just a structure; it's a reflection of your lifestyle, dreams,
                and aspirations. With a passion for excellence and a commitment
                to quality craftsmanship, we take pride in transforming houses
                into homes.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card sx={{ marginTop: 2, maxWidth: cardWidth }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="240"
              image="/Assets/work/choice.jpg"
              alt="craft"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" sx={{ color: textColor }}>
                Why Choose Us?
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ color: textColor }}>
                  <p><b>Expertise:</b> Our team of skilled professionals brings
                  years of expertise to every project, ensuring precision and
                  attention to detail.
                  </p>
                  <p><b>Personalized Approach:</b> We believe in collaboration. We
                  work closely with you to understand your vision and tailor our
                  services to meet your unique needs.
                  </p>
                  <p><b>Quality Assurance: </b> From concept to completion, we
                  uphold the highest standards of quality, ensuring that your
                  project exceeds expectations.
                  </p>
                  <p><b>Transparent Communication: </b> Keeping you informed at
                  every step, we believe in transparent communication to build
                  trust and confidence.
                  </p>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card sx={{ marginTop: 2, maxWidth: cardWidth }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="240"
              image="/Assets/work/craft2.jpeg"
              alt="craft"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" sx={{ color: textColor }}>
              Our Services
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ color: textColor }}>
                  <p><b>Renovations:</b> Transform your space with our renovation expertise, creating a home that suits your evolving needs.
                  </p>
                  <p><b>New Construction:</b> Whether it's your dream home or a new addition, we bring your vision to life with innovative design and expert construction.
                  </p>
                  <p><b>Custom Design: </b> Your home should be as unique as you are. Our custom design services ensure a space that reflects your personality and style.
                  </p>
                  <p><b>Consultation: </b> Not sure where to start? Our experts offer consultation services to guide you through the process and help you make informed decisions.
                  </p>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        


        <Card sx={{ marginTop: 2, maxWidth: cardWidth }}>
          <CardActionArea>
          
            <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={{ color: textColor }}>
                Gallery
              </Typography>
              <Typography gutterBottom variant="body2" component="div" sx={{ color: textColor }}>
              Explore our portfolio to witness the magic we bring to every project. Your dream home awaits — let's build it together!
              </Typography>
              <Slideshow maxWidth={cardWidth}/>
            </CardContent>
          </CardActionArea>
        </Card>

        
        </Stack>
        

        
      </Box>

    </Box>
  );
}

Home.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Home;
