import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, Tab, Tabs } from '@mui/material';

import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Reviews from './components/Reviews';
import MainAppBar from './components/Appbar'

function App() {
  return (
    <>
    <Router>
      <Container>
        <MainAppBar/>
        <Tabs
          value={false}
          indicatorColor="primary"
          textColor="primary"
          centered
          style={{ marginTop: 20 }}
        >
          <Tab label="Home" href="/" />
          <Tab label="About us" href="/about" />
          <Tab label="Contact us" href="/contact" />
          <Tab label="Reviews" href="/reviews" />
        </Tabs>

        <Routes>
          <Route path="/about" element={<AboutUs/>}/> 
          <Route path="/contact" element={<Contact/>}/> 
          <Route path="/reviews" element={<Reviews/>}/> 
          <Route path="/" element={<Home/>}/> 
        </Routes>
      </Container>
    </Router>
    </>
  );
}

export default App;
