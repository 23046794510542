import React from "react";
import { Stack, Paper, Typography, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import {
  companyName,
  companyShortName,
  textColor,
  cardWidth,
  contactName,
  phoneNumber,
} from "./constants";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneSharpIcon from "@mui/icons-material/PhoneSharp";

const contactInfo = [
  { contactType: "PHONE", contact: phoneNumber, contactIcon: PhoneSharpIcon },
  {
    contactType: "ADDRESS",
    contact: "1 Templeton Street, Wantirna, Victoria",
    contactIcon: PhoneSharpIcon,
  },
  {
    contactType: "EMAIL",
    contact: "jason@gmail.com",
    contactIcon: PhoneSharpIcon,
  },
];

const Contact = () => {
  return (
    <Stack
      spacing={1}
      direction="column"
      alignItems="center"
      sx={{ paddingTop: 10 }}
    >
      <Typography
        variant="h2"
        component="div"
        sx={{ paddingBottom: 0, marginBottom: 0 }}
      >
        Contact us
      </Typography>
      <Typography
        variant="body2"
        component="div"
        sx={{ paddingBottom: 5, paddingTop: 0, marginTop: 0 }}
      >
        Feel free to give us a call at the number below for a friendly and
        obligation-free chat.
      </Typography>

      <Avatar
        alt="Jason Z"
        src="/Assets/contact/Jason.jpeg"
        sx={{ width: 56, height: 56 }}
      />
      <Typography variant="h4" component="div">
        {contactName}
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        {companyName}
      </Typography>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
        alignItems="center"
      >
        {contactInfo.map((contact, index) => (
          <Grid xs={2} sm={4} md={4} key={index}>
            <Card sx={{ margin:2 }}>
              <CardContent>
                <IconButton color="primary" aria-label="Phone">
                  <PhoneIcon />
                </IconButton>
                <Typography gutterBottom variant="h5" component="div">
                  {contact.contactType}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {contact.contact}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default Contact;
