import React from "react";
import { Stack, Paper, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";


//Import reviews from google or other source later
const reviews = [
  {
    reviewerName: "Shaun",
    reviewSummary: "The Ideal Renovation Partner",
    review: `Choosing Jason for our home renovation was one of the best decisions we
                                                                                made. From the beginning, they demonstrated a high level of
                                                                                professionalism and a genuine interest in bringing our vision to life.
                                                                                What sets them apart is their excellent communication skills and the
                                                                                ability to make the renovation process smooth and stress-free. Jason is
                                                                                not only a skilled craftsman but also a friendly and approachable
                                                                                individual. They were always available to address our questions and
                                                                                concerns, making us feel like valued clients. The end result speaks
                                                                                volumes about their expertise and dedication. If you want a builder who
                                                                                combines skill with a pleasant demeanor, Jason is the perfect choice.`,
    reviewDate:'10-Oct-2021',
    imageURL:'1.jpeg',
  },
  {
    reviewerName: "Adam",
    reviewSummary: "Exceptional Service, Exceptional Person",
    review: `Jason is not just a skilled builder; they are a pleasure to work with.
                                                                                          We recently undertook a renovation project with them, and the experience
                                                                                          exceeded our expectations. Their attention to detail and commitment to
                                                                                          delivering a top-notch renovation were evident throughout. What truly
                                                                                          impressed us was their friendly and accommodating nature. Jason listened
                                                                                          to our ideas, offered valuable insights, and made the entire process
                                                                                          enjoyable. They are not just a builder; they are a partner in creating
                                                                                          your dream space. If you want a renovation expert who is both talented
                                                                                          and delightful to deal with, look no further.`,
    reviewDate:'10-Oct-2021',
    imageURL:'2.jpeg',
  },
  {
    reviewerName: "Helen",
    reviewSummary: "A Delightful Renovation Experience",
    review: ` Working with Jason on our home renovation was an absolute pleasure.
                                                                                      Right from the initial consultation, they displayed a keen understanding
                                                                                      of our needs and a genuine enthusiasm for the project. Throughout the
                                                                                      renovation, they consistently demonstrated their expertise and an
                                                                                      unwavering commitment to quality. What sets [Builder's Name] apart is
                                                                                      their friendly and approachable nature. They took the time to address
                                                                                      our concerns, explain each step of the process, and their positive
                                                                                      attitude made the entire experience stress-free. The end result is a
                                                                                      beautifully renovated home that we're proud to call our own. Highly
                                                                                      recommend!`,
    reviewDate:'10-Oct-2021',
    imageURL:'3.jpeg',
  },
  {
    reviewerName: "Troy",
    reviewSummary: "Top-Notch Renovation Team!",
    review: `Jason and their team transformed our outdated space into a modern
                                                                            masterpiece! What sets them apart is their professionalism and the
                                                                            genuine care they show for their clients. Communication was excellent,
                                                                            and they kept us informed every step of the way. [Builder's Name] went
                                                                            above and beyond to ensure we were satisfied with the progress and final
                                                                            outcome. Their friendly demeanor made the renovation process enjoyable,
                                                                            and their craftsmanship is truly commendable. If you're looking for a
                                                                            builder who not only delivers outstanding results but is also a pleasure
                                                                            to work with, Jason is the one.`,
    reviewDate:'10-Oct-2021',
    imageURL:'4.jpeg',
  },
  {
    reviewerName: "Andy M",
    reviewSummary: "Fantastic Renovation Experience!",
    review: `I recently hired Jason for a home renovation project, and I couldn't be
                                                                                    happier with the results. From the initial consultation to the final
                                                                                    touches, Jason was a pleasure to work with. Their attention to detail
                                                                                    and commitment to quality craftsmanship truly stood out. What sets them
                                                                                    apart is not just their skill but also their friendly and approachable
                                                                                    nature. They took the time to understand my vision and offered valuable
                                                                                    suggestions throughout the process. The end result exceeded my
                                                                                    expectations, and I highly recommend Jason for anyone seeking a skilled
                                                                                    and personable renovation expert.`,
    reviewDate:'10-Oct-2021',
    imageURL:'5.jpeg',
  },
];

const Reviews = ({ title, content }) => {
  //const classes = useStyles();

  return (
    <Stack spacing={2} direction="column" alignItems="center">
      {reviews.map((reviewer, index) => (
        <Card sx={{ maxWidth: '80%' }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {reviewer?.reviewerName[0]}
            </Avatar>
          }
          title={reviewer?.reviewSummary}
          subheader={reviewer.reviewDate}
        />
        <CardMedia
          component="img"
          image={`Assets/reviewImages/${reviewer.imageURL}`}
          alt="reviews"
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {reviewer.review}
          </Typography>
        </CardContent>
      </Card>
      ))}
      </Stack>
  );
};

export default Reviews;
