import React from "react";
import { Typography, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import { TabPanel, TabList, TabContext, Tab } from "@mui/lab";
import { styled } from "@mui/system";
import {
  companyName,
  companyShortName,
  textColor,
  cardWidth,
} from "./constants";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

const TeamMember = styled("div")({
  textAlign: "center",
  padding: "20px",
  margin: "10px",
  border: "1px solid #ccc",
  borderRadius: "8px",
});

const team = [
  {
    name: "Jason Zang",
    desc: "Meet Jason, a tradesperson of unparalleled skill and artistic finesse. Jason seamlessly blends technical expertise with exceptional sketching and design abilities, creating functional masterpieces. His precision and attention to detail in carpentry, plumbing, and electrical work set a new standard. Jason's sketches are not just blueprints; they're works of art, showcasing innovative solutions and personalized designs. With a client-centric approach, Jason actively collaborates to understand preferences, ensuring each project reflects the client's vision. Passionate about perfection, Jason's commitment transforms every trade into a harmonious blend of functionality and aesthetic brilliance.",
    imageURL: "Assets/contact/jason.jpg",
  },
  {
    name: "Bill Robert",
    desc: "Meet Robert, a carpentry virtuoso whose skills transcend the ordinary. With a keen eye for detail and a masterful touch, Robert transforms wood into functional works of art. His craftsmanship is marked by precision, from intricately carved details to seamlessly constructed structures. Robert's expertise in carpentry goes beyond technical proficiency; it embodies a passion for the craft. Clients entrust their visions to Robert, confident in his ability to turn ideas into beautifully crafted realities. Whether it's custom furniture, architectural features, or meticulous repairs, Robert's dedication to excellence makes him a standout in the world of carpentry, leaving a lasting impression with every project.",
    imageURL: "Assets/contact/jason.jpg",
  },
  {
    name: "Mike Schindler",
    desc: "Meet Mike, a plumbing maestro whose expertise flows effortlessly. With a profound understanding of pipes, fixtures, and systems, Mike is a troubleshooter extraordinaire. His skill set extends beyond mere repairs; it's a mastery of the plumbing craft. Mike navigates intricate pipelines with precision, effortlessly solving plumbing puzzles. Clients rely on Mike's dependable and efficient service, confident that their plumbing concerns will be expertly addressed. Mike's commitment to quality workmanship and his knack for innovative solutions make him a standout in the world of plumbing. Whether it's installations, repairs, or consultations, Mike's plumbing prowess ensures a seamless and reliable water infrastructure for every project.",
    imageURL: "Assets/contact/jason.jpg",
  },
  {
    name: "Kevin Blight",
    desc: "Meet Kevin, a luminary in electrical work and appliance installation. His expertise shines in every project, showcasing a profound understanding of wiring and electrical systems. Beyond conventional tasks, Kevin excels in intricate appliance installations, marrying technical precision with a meticulous touch. Clients appreciate his knack for seamlessly integrating cutting-edge appliances and troubleshooting electrical complexities. Kevin's dedication to staying current with technology transforms spaces into modern, energy-efficient havens. In the world of electrical work, Kevin is the trusted artisan, turning visions into brilliantly powered realities with unmatched skill and finesse.",
    imageURL: "Assets/contact/jason.jpg",
  },
  {
    name: "Mark Stewart",
    desc: "Meet Mark, a flooring virtuoso whose expertise transforms spaces into exquisite showcases. With an impeccable eye for detail and a passion for precision, Mark excels in the art of flooring work. Whether it's intricate tile patterns, flawless hardwood installations, or resilient laminate flooring, Mark's craftsmanship elevates every project. Clients admire his ability to blend aesthetics with functionality, creating floors that stand the test of time. Mark's dedication to perfection and a deep understanding of materials make him the go-to expert for those seeking not just flooring solutions but a touch of elegance underfoot in every room.",
    imageURL: "Assets/contact/jason.jpg",
  },
];

const AboutUs = () => {
  return (
    <>
      <div>  
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ color: textColor }}
        >
          <p>
            Learn more about our company and our expertise in renovating
            bathrooms and kitchens in Melbourne.
          </p>
        </Typography>
        {/* Team Section */}
        <Typography variant="h4" mt={4}>
          Our Team
        </Typography>

        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
              {team.map((member, index) => (
                <Grid xs={2} sm={4} md={4} key={index}>
                  <Card sx={{ maxWidth: 300 }}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="140"
                        image={member.imageURL}
                        alt="green iguana"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {member.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" >
                          <Box sx={{maxHeight:100, overflowY: "scroll" }}>{member.desc}</Box>
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>

       
      </div>
    </>
  );
};

export default AboutUs;
